html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh; /* Ensure full viewport height */
  background-color: #282c34; /* Set the background color */
  color: white; /* Adjust text color for readability */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 500px;
  height: auto;
}

.headline {
  margin-top: 20px;
}

.pdf-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.pdf-link-button {
  margin: 10px 0;
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.pdf-link-button:hover, .pdf-link-button:focus {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

.pdf-link-button:active {
  background-color: #004494;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.category-button {
  margin: 10px; /* Adjust as needed for spacing */
}
